<template>
  <div class="d-flex align-center justify-center pt-8">
    <v-card min-width="91%" outlined class="rounded-lg">
      <div
        class="
          d-flex
          flex-column flex-sm-row
          justify-center justify-sm-space-around
          smallText
          px-4
          py-2
        "
      >
        <p class="px-auto mb-0 grey--text">
          <strong class="primary--text">Email:</strong> {{ email }}
        </p>
        <p class="px-auto mb-0 grey--text">
          <strong class="primary--text">Ref:</strong> {{ refID }}
        </p>
      </div>
      <template>
        <v-simple-table dense>
          <template v-slot:default>
            <thead class="px-0">
              <tr class="px-0 grey--text darken-4 font-weight-bold">
                <th
                  class="
                    px-0
                    text-center text-subtitle-2
                    primary--text
                    font-weight-bold
                  "
                >
                  First Name
                </th>
                <th
                  class="
                    px-0
                    text-center text-subtitle-2
                    primary--text
                    font-weight-bold
                  "
                >
                  Last Name
                </th>
                <th
                  class="
                    px-0
                    text-center text-subtitle-2
                    primary--text
                    font-weight-bold
                  "
                >
                  Role
                </th>
              </tr>
            </thead>
            <tbody class="px-1">
              <tr class="px-1">
                <td class="px-1 text-center">
                  {{ first_name }}
                </td>
                <td class="px-1 text-center">{{ last_name }}</td>
                <td
                  class="px-1 text-center text-truncate"
                  style="max-width: 60px"
                >
                  {{ role }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <v-row class="pa-0 ma-0">
        <v-col>
          <template>
            <v-simple-table dense>
              <template v-slot:default>
                <thead class="px-0">
                  <tr class="px-0">
                    <th
                      class="
                        px-0
                        text-center text-subtitle-2
                        primary--text
                        font-weight-bold
                      "
                    >
                      Bank Account
                    </th>
                    <th
                      class="
                        px-0
                        text-center text-subtitle-2
                        primary--text
                        font-weight-bold
                      "
                    >
                      Bank
                    </th>
                    <th
                      class="
                        px-0
                        text-center text-subtitle-2
                        primary--text
                        font-weight-bold
                      "
                    ></th>
                  </tr>
                </thead>
                <tbody class="px-2">
                  <tr class="px-2">
                    <td class="px-2 text-center">
                      <v-chip small class="px-4">{{ bankaccount }}</v-chip>
                    </td>
                    <td class="px-2 text-center">{{ bankname }}</td>
                    <td class="px-2 text-center">
                      <v-hover v-slot="{ hover }" v-if="role !== 'admin'">
                        <v-btn
                          :style="{
                            'background-color': hover ? '#2bd5ae' : '',
                            border: hover ? 'none' : '',
                          }"
                          exact-path
                          depressed
                          outlined
                          dark
                          small
                          color="#2BD5AE"
                          class="text-capitalize px-1 my-1 mr-1 rounded"
                          style="color: var(--v-primary-base)"
                        >
                          Remove
                        </v-btn>
                      </v-hover>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TeamTableCard",
  props: {
    index: {
      type: Number,
    },
    // id: {
    //   type: Number,
    // },
    role: {
      type: String,
    },
    first_name: {
      type: String,
    },
    last_name: {
      type: String,
    },
    refID: {
      type: String,
    },
    bankaccount: {
      type: [String, Number],
    },
    // phone: {
    //   type: String,
    // },
    email: {
      type: String,
    },
    bankname: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
/* .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 8px !important;
} */

.v-application .elevation-3 {
  box-shadow: -4px 3px 1px -2px rgb(0 0 0 / 4%), 3px 2px 2px 0px rgb(0 0 0 / 5%),
    3px 1px 5px 0px rgb(0 0 0 / 5%) !important;
}

.smallText {
  font-size: medium;
}
</style>
