<template>
  <div class="pb-6">
    <div class="text-center mt-6" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <v-layout
        row
        wrap
        class="align-center pt-6 pb-6 mx-0 text-body-2"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md1>
          <div class="d-flex align-center px-6 ma-0">
            <p class="mb-0 primary--text font-weight-bold">#</p>
          </div>
        </v-flex>
        <v-flex md3 class="">
          <v-layout row class="align-center">
            <v-flex>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold">First Name</p>
              </div>
            </v-flex>
            <v-flex>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold">Last Name</p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md3 class="">
          <v-layout row wrap class="align-center">
            <v-flex md6>
              <div class="">
                <p class="mb-0 primary--text font-weight-bold">Ref</p>
              </div>
            </v-flex>
            <v-flex md6>
              <div class="">
                <p class="mb-0 primary--text font-weight-bold">Email</p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md3 class="">
          <v-layout row wrap class="align-center">
            <v-flex md6>
              <div class="">
                <p class="mb-0 primary--text font-weight-bold">Account</p>
              </div>
            </v-flex>
            <v-flex md6>
              <div class="">
                <p class="mb-0 primary--text font-weight-bold">Bank</p>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Role</p>
          </div>
        </v-flex>
        <v-flex md1 class="">
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Action</p>
          </div>
        </v-flex>
      </v-layout>

      <v-skeleton-loader :type="skeletonShape" :loading="loading">
        <v-row v-if="filteredTeam && filteredTeam.length > 0">
          <v-col
            cols="12"
            class="py-0 ma-0"
            v-for="(member, i) in displayedItems"
            :key="i"
          >
            <TeamTable
              v-if="$vuetify.breakpoint.mdAndUp"
              :index="i + 1"
              :id="member.id"
              :first_name="member.first_name || 'N/A'"
              :last_name="member.last_name || 'N/A'"
              :email="member.email || 'N/A'"
              :bankaccount="member.bank.accountnumber"
              :bankname="member.bank.bankname"
              :role="member.role"
              :refID="member.hypn_id"
            />
            <!-- Data table for mobile -->
            <TeamTableCard
              v-if="$vuetify.breakpoint.smAndDown"
              :index="i + 1"
              :id="member.id"
              :first_name="member.first_name || 'N/A'"
              :last_name="member.last_name || 'N/A'"
              :email="member.email || 'N/A'"
              :bankaccount="member.bank.accountnumber"
              :bankname="member.bank.bankname"
              :role="member.role"
              :refID="member.hypn_id"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-center align-center pt-6" v-else>
          <span class="text--primary text-h5 font-monospace"
            >No Data available</span
          >
        </div>
        <!-- pagination component -->
        <div
          class="pagination__container px-8"
          v-if="filteredTeam && filteredTeam.length > 0"
        >
          <BasePagination
            :current-page="currentPage"
            :visible-pages="visiblePageValue"
            :total-pages="totalPages"
            @page-changed="handlePageChange"
          />
        </div>
      </v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import TeamTable from "@/pages/settings/components/TeamTable.vue";

import { mapActions, mapGetters } from "vuex";
import TeamTableCard from "@/pages/settings/components/TeamTableCard.vue";
import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination";
export default {
  name: "Team",
  components: {
    TeamTable,
    TeamTableCard,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      dialog: false,

      team: null,

      searchQuery: "",
      itemsPerPage: 5,
      loading: true,
    };
  },

  computed: {
    ...mapGetters({
      teamMembers: "auth/getTeamMembers",
    }),
    skeletonShape() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "table";
      } else {
        return "card";
      }
    },
    // searchQuery filter for teams
    filteredTeam() {
      if (this.searchQuery) {
        return this.team?.filter((member) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => {
              return (
                member.first_name?.toLowerCase().includes(v) ||
                member.email?.toLowerCase().includes(v) ||
                member.last_name?.toLowerCase().includes(v)
              );
            });
        });
      } else return this.team;
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredTeam.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (this.filteredTeam && this.filteredTeam.length > this.itemsPerPage) {
        return this.filteredTeam.slice(startIndex, endIndex);
      } else {
        return this.filteredTeam.slice();
      }
    },
    totalPages() {
      return Math.ceil(this.filteredTeam.length / this.itemsPerPage);
    },
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    closeModal() {
      this.dialog = false;
    },
    setSearchText(value) {
      this.searchQuery = value;
    },
    alertCSVDownload() {
      this.showToast({
        sclass: "success",
        show: true,
        message: "CSV downloaded!",
        timeout: 3000,
      });
    },

    // method to dispatch overview page data so as to get the team array, remember orgHypnId is globally available
    async getData() {
      try {
        const { data } = await this.$store.dispatch(
          "auth/getTeamMembers",
          this.orgId
        );
        this.team = data;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });
      }
    },
  },

  mounted() {
    this.loading = true;
    // just a setTimeout to delay the dataTable values from mounting
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  },

  watch: {
    orgId: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getData();
        }
      },
    },
    teamMembers: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.team = val;
        }
      },
    },
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px !important;
  display: inline-block !important;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
