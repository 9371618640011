<template>
  <div>
    <BaseTitleBar
      pageTitle="Team"
      :itemsText="$orgDetails ? $orgDetails.orgname : ''"
    >
      <InviteStakeholder v-if="items[tab].tab === 'Team'">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          color="primary"
          class="px-md-8 px-4"
          :height="btnHeight[$vuetify.breakpoint.name]"
          justify="right"
          style="background: var(--v-primary-base); border-radius: 4px"
        >
          <img
            :src="btnIcon"
            alt="button svg icon"
            style="padding-right: 8px"
            :style="{
              width: `${$vuetify.breakpoint.mdAndUp ? '24px' : '17px'}`,
              height: `${$vuetify.breakpoint.mdAndUp ? '18px' : '12px'}`,
            }"
          />
          <span class="text-capitalize btn__text"> Add New </span>
        </v-btn>
      </InviteStakeholder>
    </BaseTitleBar>

    <v-container class="mt-md-12">
      <v-row class="mx-md-10 pa-0">
        <v-col class="d-flex flex-column align-center justify-center" cols="12">
          <v-card
            elevation="6"
            v-if="$vuetify.breakpoint.mdAndUp"
            flat
            width="100%"
            style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
          >
            <v-tabs slider-color="primary" v-model="tab" slider-size="4">
              <v-tab
                class="mt-2"
                v-for="item in items"
                :key="item.tab"
                :style="{
                  color: `${item.tab == 'Pending' ? '#ff6a6a' : ''}`,
                }"
                style="
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 12px;
                  line-height: 15px;
                  text-transform: uppercase;
                "
                >{{ item.tab }}</v-tab
              >

              <v-spacer></v-spacer>
              <div v-if="items[tab].tab === 'Team'">
                <v-btn
                  v-if="isClicked"
                  @click="toggleSearch"
                  plain
                  class="text-black mt-1 pt-4"
                  style="
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.55px;
                    text-transform: uppercase;
                    color: #7f919b;
                  "
                >
                  search
                  <v-icon small right class="pr-1"> mdi-magnify </v-icon>
                </v-btn>
                <v-expand-x-transition v-else>
                  <v-text-field
                    v-model="search"
                    @input="searchDataTable"
                    @blur="isClicked = true && !search"
                    class="seacrh-field mt-2 mr-2"
                    dense
                    clearable
                    autofocus
                    hide-details="true"
                    persistent-placeholder
                    placeholder="Search"
                    append-icon="mdi-magnify"
                    filled
                  >
                  </v-text-field>
                </v-expand-x-transition>
              </div>
            </v-tabs>
          </v-card>

          <v-card width="100%" color="#fcfdfd" elevation="6" flat>
            <div class="ma-0 pa-0">
              <Btn-card />
            </div>
            <component
              v-bind:is="items[tab].content"
              ref="settingsDataTable"
              class="ml-0"
            ></component>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- tabs for mobile devices -->
    <v-row
      class="px-0 mx-0"
      style="margin-top: 10vh"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-col class="ma-0 pa-0" cols="12">
        <v-bottom-navigation fixed class="pa-0 mx-0" dark>
          <v-tabs
            center-active
            class="ma-0"
            background-color="primary"
            v-model="tab"
          >
            <v-tab
              class="mt-2"
              v-for="item in items"
              :key="item.tab"
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
              "
              >{{ item.tab }}</v-tab
            >
          </v-tabs>
        </v-bottom-navigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseTitleBar from "@/components/BaseTitleBar.vue";
import Team from "@/pages/settings/Team.vue";
import BtnCard from "@/pages/settings/components/btnCard.vue";
import InviteStakeholder from "@/pages/invoices/components/inviteStakeholder.vue";
export default {
  name: "Teams",
  data() {
    return {
      tab: 0,
      icon: "uploadIcon",
      isClicked: true,
      search: "",
      items: [{ tab: "Team", content: "Team" }],
      btnHeight: {
        xs: "36px",
        md: "54px",
        lg: "54px",
        sm: "44px",
      },
    };
  },

  components: {
    BaseTitleBar,
    InviteStakeholder,
    Team,
    BtnCard,
  },

  methods: {
    toggleSearch() {
      this.isClicked = false;
    },
    searchDataTable(e) {
      this.$refs.settingsDataTable.setSearchText(e);
      //console.log(e);
    },
  },
  computed: {
    btnIcon() {
      return require(`@/assets/pbot_icons/${this.icon}.svg`);
    },
  },
};
</script>

<style scoped>
.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  box-shadow: none;
  border-radius: none;
}

.btn__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.636364px;
}

.parent-card {
  width: 227px;
  background: #ffffff;
}

.list__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #7f919b;
}

.list__subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #7f919b;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.list__icon {
  mix-blend-mode: normal;
  opacity: 0.5;
}

@media only screen and (min-width: 600px) {
  .btn__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.636364px;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .btn__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.636364px;
  }
}
</style>
