<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="px-6 py-6"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
      >
        <v-layout row wrap class="align-center">
          <v-flex md1 class="d-flex">
            <v-avatar dark size="24" :color="randomColor" class="">
              <span class="white--text text-caption">{{
                firstNameLetter
              }}</span>
            </v-avatar>
          </v-flex>
          <v-flex md3 class="d-flex align-center">
            <v-layout row wrap class="align-center">
              <v-flex md6>
                <span class="mb-0 table__text">{{ first_name }}</span>
              </v-flex>
              <v-flex md6>
                <span class="mb-0 table__text">{{ last_name }}</span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md3 class="d-flex align-center">
            <v-layout row wrap class="align-center">
              <v-flex md6>
                <div class="d-flex align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="text-truncate table__text"
                        style="max-width: 90%"
                      >
                        {{ refID }}
                      </span>
                    </template>
                    <span>{{ refID }}</span>
                  </v-tooltip>
                </div>
              </v-flex>
              <v-flex md6>
                <div class="d-flex align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="text-truncate table__text"
                        style="max-width: 70%"
                      >
                        {{ email }}
                      </span>
                    </template>
                    <span>{{ email }}</span>
                  </v-tooltip>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md3 class="d-flex align-center">
            <v-layout row wrap class="align-center">
              <v-flex md6>
                <div class="d-flex justify-start">
                  <span class="table__text">
                    {{ bankaccount }}
                  </span>
                </div>
              </v-flex>
              <v-flex md6>
                <div class="d-flex align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="table__text text-truncate"
                        style="max-width: 70%"
                        >{{ bankname }}</span
                      >
                    </template>
                    <span>{{ bankname }}</span>
                  </v-tooltip>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <span class="pr-4 table__text">{{ role }}</span>
            </div>
          </v-flex>

          <v-flex md1>
            <div
              class="d-flex justify-center align-center"
              v-if="role !== 'admin'"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  :style="{
                    'background-color': hover ? '#2bd5ae' : '',
                    border: hover ? 'none' : '',
                  }"
                  exact-path
                  depressed
                  outlined
                  dark
                  small
                  color="#2BD5AE"
                  class="text-capitalize px-1 my-1 mr-1 rounded"
                  style="color: var(--v-primary-base)"
                >
                  Remove
                </v-btn>
              </v-hover>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "TeamTable",
  props: {
    index: {
      type: Number,
    },
    // id: {
    //   type: Number,
    // },
    role: {
      type: String,
    },
    first_name: {
      type: String,
    },
    last_name: {
      type: String,
    },
    refID: {
      type: String,
    },
    bankaccount: {
      type: [String, Number],
    },
    // phone: {
    //   type: String,
    // },
    email: {
      type: String,
    },
    bankname: {
      type: String,
    },
  },
  data() {
    return {
      //
    };
  },

  computed: {
    firstNameLetter() {
      return this.first_name.charAt(0).toUpperCase();
    },

    // generate a random avatar hexa color apart from white and grey
    randomColor() {
      const colors = [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#9e9e9e",
        "#607d8b",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
  },
};
</script>

<style scoped>
.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

v-btn .button {
  transition-duration: 0.4s;
}

v-btn .button:hover {
  background-color: #19283d;
  color: white;
}

.table__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #596a73;
}
</style>
