var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-6 py-6",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"d-flex",attrs:{"md1":""}},[_c('v-avatar',{attrs:{"dark":"","size":"24","color":_vm.randomColor}},[_c('span',{staticClass:"white--text text-caption"},[_vm._v(_vm._s(_vm.firstNameLetter))])])],1),_c('v-flex',{staticClass:"d-flex align-center",attrs:{"md3":""}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md6":""}},[_c('span',{staticClass:"mb-0 table__text"},[_vm._v(_vm._s(_vm.first_name))])]),_c('v-flex',{attrs:{"md6":""}},[_c('span',{staticClass:"mb-0 table__text"},[_vm._v(_vm._s(_vm.last_name))])])],1)],1),_c('v-flex',{staticClass:"d-flex align-center",attrs:{"md3":""}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md6":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-truncate table__text",staticStyle:{"max-width":"90%"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.refID)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.refID))])])],1)]),_c('v-flex',{attrs:{"md6":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-truncate table__text",staticStyle:{"max-width":"70%"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.email)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.email))])])],1)])],1)],1),_c('v-flex',{staticClass:"d-flex align-center",attrs:{"md3":""}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md6":""}},[_c('div',{staticClass:"d-flex justify-start"},[_c('span',{staticClass:"table__text"},[_vm._v(" "+_vm._s(_vm.bankaccount)+" ")])])]),_c('v-flex',{attrs:{"md6":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"table__text text-truncate",staticStyle:{"max-width":"70%"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.bankname))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.bankname))])])],1)])],1)],1),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"pr-4 table__text"},[_vm._v(_vm._s(_vm.role))])])]),_c('v-flex',{attrs:{"md1":""}},[(_vm.role !== 'admin')?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"}},[_vm._v(" Remove ")])]}}],null,true)})],1):_vm._e()])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }